import { ReactNode, useRef } from 'react';

import auth from 'auth';
import useBoolean from 'hooks/useBoolean';

import { AuthContext } from './AuthProvider.context';

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props): JSX.Element => {
  const [isSessionRefreshed, { on }] = useBoolean(false);
  const sessionRef = useRef<boolean | undefined>(isSessionRefreshed);

  const markAsRefreshed = () => {
    on();
    sessionRef.current = true;
  };

  const checkIfSessionIsRefreshed = async (trailingExecution = false): Promise<boolean> => {
    if (trailingExecution) {
      await new Promise((resolveTimeout) => {
        setTimeout(resolveTimeout, 10);
      });
    }
    return new Promise((resolve) => {
      if (sessionRef.current) {
        resolve(true);
      } else {
        resolve(checkIfSessionIsRefreshed(true));
      }
    });
  };

  return (
    <AuthContext.Provider value={{ auth, isSessionRefreshed, checkIfSessionIsRefreshed, markAsRefreshed }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
