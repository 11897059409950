import { createContext } from 'react';

import auth, { Auth } from 'auth';

type AuthContextType = {
  auth: Auth;
  isSessionRefreshed: boolean;
  markAsRefreshed: () => void;
  checkIfSessionIsRefreshed: () => Promise<boolean>;
};

export const AuthContext = createContext<AuthContextType>({
  auth,
  isSessionRefreshed: false,
  markAsRefreshed: () => {},
  checkIfSessionIsRefreshed: async () => false,
});
