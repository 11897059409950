import { createContext } from 'react';

import { Variant } from '@templates/Alert';

export type SnackbarContextType = {
  showMessage: (type: Variant, message: string, timeout?: number) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  showMessage: () => {},
});
