import { createContext } from 'react';

import { UserData } from 'types/UserData';
import { SessionVars } from 'types/SessionVars';
import { AddressType } from 'types/models/Address';
import {
  getUserRoute,
  createAccountRoute,
  changeEmailRoute,
  requestEmailChangeRoute,
  changePasswordRoute,
  resetPasswordRoute,
  resendActivationLinkRoute,
} from 'apiRoutes/users';
import {
  createAddressRoute,
  deleteAddressRoute,
  updateAddressRoute,
  setPrimaryAddressRoute,
} from 'apiRoutes/users/addresses';
import { createSessionRoute, getSessionVarsRoute, updateSessionVarsRoute } from 'apiRoutes/session';

import { GetVariables } from 'utils/apiRoute';
import { FetchResult } from 'hooks/useFetch';

export type UserContextType = {
  isLoggedIn: () => boolean;
  userData?: UserData;
  sessionVars?: SessionVars;
  isSessionRefreshed?: boolean;
  areSessionVarsRefreshed?: boolean;
  fetchUser: () => Promise<FetchResult<typeof getUserRoute>>;
  register: (params: GetVariables<typeof createAccountRoute>) => Promise<FetchResult<typeof createAccountRoute>>;
  login: (params: GetVariables<typeof createSessionRoute>) => Promise<FetchResult<typeof createSessionRoute>>;
  logout: (withLogoutMutation?: boolean) => Promise<FetchResult<typeof createSessionRoute>>;
  createAddress: (
    variables: GetVariables<typeof createAddressRoute>
  ) => Promise<FetchResult<typeof createAddressRoute>>;
  updateAddress: (
    id: number,
    variables: Omit<GetVariables<typeof updateAddressRoute>, 'id'>
  ) => Promise<FetchResult<typeof updateAddressRoute>>;
  setPrimaryAddress: (id: number, type: AddressType) => Promise<FetchResult<typeof setPrimaryAddressRoute>>;
  deleteAddress: (id: number) => Promise<FetchResult<typeof deleteAddressRoute>>;
  resendActivationLink: (
    params: GetVariables<typeof resendActivationLinkRoute>
  ) => Promise<FetchResult<typeof resendActivationLinkRoute>>;
  editUser: ({ firstName, lastName }: Pick<UserData, 'firstName' | 'lastName' | 'phone'>) => Promise<void>;
  requestEmailChange: ({
    newEmail,
  }: GetVariables<typeof requestEmailChangeRoute>) => Promise<FetchResult<typeof requestEmailChangeRoute> | undefined>;
  editEmail: ({
    token,
  }: GetVariables<typeof changeEmailRoute>) => Promise<FetchResult<typeof changeEmailRoute> | undefined>;
  resetPassword: (
    variables: GetVariables<typeof resetPasswordRoute>
  ) => Promise<FetchResult<typeof resetPasswordRoute>>;
  changePassword: (
    params: GetVariables<typeof changePasswordRoute>
  ) => Promise<FetchResult<typeof changePasswordRoute>>;
  getSessionVar: <T extends keyof SessionVars>(key: T) => SessionVars[T] | undefined;
  setSessionVar: <T extends keyof SessionVars>(
    key: T,
    value: SessionVars[T]
  ) => Promise<FetchResult<typeof updateSessionVarsRoute>>;
  refreshSessionVars: () => Promise<FetchResult<typeof getSessionVarsRoute>>;
};

const defaultHandler = async () => ({
  data: undefined,
  error: undefined,
  loading: false,
  cacheHit: false,
});

export const UserContext = createContext<UserContextType>({
  isLoggedIn: () => false,
  userData: undefined,
  sessionVars: undefined,
  register: defaultHandler,
  resendActivationLink: defaultHandler,
  login: defaultHandler,
  logout: defaultHandler,
  fetchUser: defaultHandler,
  createAddress: defaultHandler,
  updateAddress: defaultHandler,
  setPrimaryAddress: defaultHandler,
  deleteAddress: defaultHandler,
  editUser: async () => {},
  editEmail: defaultHandler,
  requestEmailChange: defaultHandler,
  resetPassword: defaultHandler,
  changePassword: defaultHandler,
  getSessionVar: () => undefined,
  setSessionVar: defaultHandler,
  refreshSessionVars: defaultHandler,
});
