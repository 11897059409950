/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['default', 'info', 'error', 'success'] }] */
import { ReactNode, useState } from 'react';
import classNames from 'classnames';

import Message from 'types/Message';
import Alert, { Variant } from '@templates/Alert';
import getRandomId from 'utils/getRandomId';
import createStyleVariables from 'utils/createStyleVariables';

import { defaultAlertDisplayTime, exitAnimationTime } from './SnackbarProvider.consts';
import { SnackbarContext } from './SnackbarProvider.context';
import classes from './SnackbarProvider.module.scss';

type Props = {
  children: ReactNode;
};

type Item = {
  id: string;
  type: Variant;
  message: Message;
  isExiting: boolean;
};

const { Provider } = SnackbarContext;

const SnackbarProvider = ({ children }: Props): JSX.Element => {
  const [messages, setMessages] = useState<Item[]>([]);

  const onClose = (id: string) => {
    setMessages((currentMessages) =>
      currentMessages.map((message) => ({
        ...message,
        isExiting: message.id === id ? true : message.isExiting,
      }))
    );
    setTimeout(
      () => setMessages((currentMessages) => currentMessages.filter((message) => message.id !== id)),
      exitAnimationTime
    );
  };

  const showMessage = (type: Variant, message: string, timeout: number = defaultAlertDisplayTime) => {
    const id = getRandomId('snackbar');

    setMessages((currentMessages) => [{ id, type, message, isExiting: false }, ...currentMessages]);

    if (timeout > 0) {
      setTimeout(() => onClose(id), timeout - exitAnimationTime);
    }
  };

  return (
    <Provider value={{ showMessage }}>
      <div className={classes.wrapper}>
        <div className={classes.innerWrapper}>
          {messages.map(({ id, type, message, isExiting }) => (
            <div
              key={id}
              className={classNames(classes.message, { [classes.isExiting]: isExiting })}
              style={createStyleVariables({ exitAnimationTime: `${exitAnimationTime}ms` })}
            >
              <Alert variant={type} onClearClick={() => onClose(id)}>
                {message}
              </Alert>
            </div>
          ))}
        </div>
      </div>
      {children}
    </Provider>
  );
};

export default SnackbarProvider;
