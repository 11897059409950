const CHECKOUT = 'checkout';
const QUOTATION = 'quotation';
const PRODUCT = 'product';
const OLD_PRODUCT = 'old-product';
const RESET_PASSWORD = 'reset-password';
const ARTICLES = 'artykuly';
const CONFERENCES = 'szkolenia-it';

const routes = {
  checkout: {
    href: `/${CHECKOUT}`,
    cart: {
      href: `/${CHECKOUT}/cart`,
    },
    delivery: {
      href: `/${CHECKOUT}/delivery`,
    },
    summary: {
      href: `/${CHECKOUT}/summary`,
    },
    confirmation: {
      id: {
        slug: 'orderId',
        href: `/${CHECKOUT}/confirmation/[orderId]`,
      },
    },
    failure: {
      id: {
        slug: 'orderId',
        href: `/${CHECKOUT}/failure/[orderId]`,
      },
    },
    proforma: {
      id: {
        slug: 'orderId',
        href: `/${CHECKOUT}/proforma/[orderId]`,
      },
    },
    invoice: {
      id: {
        slug: 'orderId',
        href: `/${CHECKOUT}/invoice/[orderId]`,
      },
    },
  },
  quotation: {
    href: `/${QUOTATION}`,
    confirmation: {
      id: {
        slug: 'id',
        href: `/${QUOTATION}/confirmation/[id]`,
      },
    },
  },
  complexDelivery: {
    href: '/kompleksowe-dostawy',
  },
  publicSector: {
    href: '/sektor-publiczny',
  },
  aboutUs: {
    href: '/o-nas',
  },
  orders: {
    href: '/orders',
  },
  order: {
    id: {
      href: '/order/[orderId]',
    },
  },
  myAccount: {
    href: '/my-account',
  },
  invoiceData: {
    href: '/invoice-data',
  },
  addresses: {
    href: '/addresses',
  },
  lastSeen: {
    href: '/last-seen',
  },
  search: {
    href: '/search',
    alias: {
      href: '/search/[alias]',
    },
  },
  model: {
    id: {
      slug: 'productId',
      nameSlug: 'name',
      href: '/model/[name]/[productId]',
    },
  },
  product: {
    id: {
      slug: 'productId',
      nameSlug: 'name',
      href: `/${PRODUCT}/[name]/[productId]`,
    },
  },
  oldProduct: {
    id: {
      slug: 'productId',
      nameSlug: 'name',
      href: `/${OLD_PRODUCT}/[name]/[productId]`,
    },
  },
  privacyPolicy: {
    href: '/polityka_prywatnosci',
  },
  cookiePolicy: {
    href: '/polityka-cookies',
  },
  termsOfService: {
    href: '/regulamin_serwisu',
  },
  termsOfSales: {
    href: '/regulamin_sprzedazy',
  },
  login: {
    href: '/logowanie',
  },
  register: {
    href: '/rejestracja',
  },
  contact: {
    href: '/kontakt',
  },
  faq: {
    href: '/faq',
  },
  home: {
    href: '/',
  },
  resetPassword: {
    href: `/${RESET_PASSWORD}`,
    confirmation: {
      href: `/${RESET_PASSWORD}/confirmation`,
    },
  },
  compare: {
    href: '/porownanie',
  },
  conferences: {
    href: `/${CONFERENCES}`,
    name: {
      href: `/${CONFERENCES}/[name]`,
      unsubscribe: {
        href: `/${CONFERENCES}/[name]/[unsubscribe]`,
      },
    },
  },
  articles: {
    href: `/${ARTICLES}`,
    category: {
      href: `/${ARTICLES}/[category]`,
      tag: {
        href: `/${ARTICLES}/[category]/[tag]`,
      },
    },
  },
  article: {
    name: {
      href: '/artykul/[name]',
    },
  },
  brandsZone: {
    apc: {
      href: '/strefa-marek/apc',
    },
  },
  category: {
    slug: {
      href: '/kategoria/[slug]',
    },
  },
  legalDocument: {
    id: {
      href: '/dokumenty-prawne/[id]',
    },
  },
};

export default { routes };
